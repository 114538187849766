import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/provider';
import { extendTheme } from '@chakra-ui/theme-utils';
import { createStandaloneToast } from '@chakra-ui/toast';
import '../styles/globals.css';
import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IpeUser } from '@models/models/models.types';
import { SupabaseProvider } from '@services/supabase.service';
import PlausibleProvider from 'next-plausible';
import { useEffect, useState } from 'react';
const theme = extendTheme({});
const queryClient = new QueryClient();
const {
  ToastContainer
} = createStandaloneToast();
type InitialProps = {
  user: IpeUser;
};
function App({
  Component,
  pageProps
}: AppProps<InitialProps>) {
  const [showUpdateBanner, setShowUpdateBanner] = useState(false);
  const updateNow = () => {
    setShowUpdateBanner(false);
    navigator.serviceWorker.getRegistration().then(registration => {
      const waitingServiceWorker = registration?.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.postMessage({
          action: 'skipWaiting'
        });
      }
    });
  };
  useEffect(() => {
    if (typeof window != 'undefined' && 'serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js').then(registration => {
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          newWorker?.addEventListener('statechange', _ => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              setShowUpdateBanner(true);
            }
          });
        });
      });
      let refreshing = false;
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.debug('pwa:controllerchange refreshing', refreshing);
        if (refreshing) return;
        window.location.reload();
        refreshing = true;
      });
      return () => {
        console.debug('pwa:disposing');
      };
    }
  }, []);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="application-name" content="ipê" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="apple-mobile-web-app-capable" content="yes" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="apple-mobile-web-app-title" content="ipê" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="description" content="gestão de pedidos de iluminação pública" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="format-detection" content="telephone=no" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="mobile-web-app-capable" content="yes" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="msapplication-TileColor" content="#805AD5" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="msapplication-tap-highlight" content="no" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="theme-color" content="#805AD5" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />

        <link rel="icon" type="image/png" sizes="196x196" href="/icons/favicon-196.png" />
        <link rel="apple-touch-icon" href="/icons/apple-icon-180.png" />
        <meta name="apple-mobile-web-app-capable" content="yes" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2048-2732.png" media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2732-2048.png" media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1668-2388.png" media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2388-1668.png" media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1536-2048.png" media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2048-1536.png" media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1668-2224.png" media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2224-1668.png" media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1620-2160.png" media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2160-1620.png" media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1290-2796.png" media="(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2796-1290.png" media="(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1179-2556.png" media="(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2556-1179.png" media="(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1284-2778.png" media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2778-1284.png" media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1170-2532.png" media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2532-1170.png" media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1125-2436.png" media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2436-1125.png" media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1242-2688.png" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2688-1242.png" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-828-1792.png" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1792-828.png" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1242-2208.png" media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-2208-1242.png" media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-750-1334.png" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1334-750.png" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-640-1136.png" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
        <link rel="apple-touch-startup-image" href="/icons/apple-splash-1136-640.png" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />

        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="shortcut icon" href="/favicon.ico" />
        {/* <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=optional" /> */}

        <meta name="twitter:card" content="summary" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="twitter:url" content="https://ipe.techx.pt" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="twitter:title" content="ipê" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="twitter:description" content="gestão de pedidos de iluminação pública" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="twitter:image" content="https://ipe.techx.pt/icons/icon-192.png" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        {/* <meta name="twitter:creator" content="@techx" /> */}
        <meta property="og:type" content="website" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:title" content="ipê" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:description" content="gestão de pedidos de iluminação pública" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:site_name" content="ipê" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:url" content="https://ipe.techx.pt" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:image" content="https://ipe.techx.pt/icons/apple-touch-icon.png" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      <ChakraProvider resetCSS theme={theme} data-sentry-element="ChakraProvider" data-sentry-source-file="_app.tsx">
        <ToastContainer data-sentry-element="ToastContainer" data-sentry-source-file="_app.tsx" />
        <SupabaseProvider user={pageProps.user} data-sentry-element="SupabaseProvider" data-sentry-source-file="_app.tsx">
          <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
            <PlausibleProvider domain="ipe.techx.pt" customDomain="https://analytics.techx.pt" selfHosted={true} hash={false} data-sentry-element="PlausibleProvider" data-sentry-source-file="_app.tsx">
              {showUpdateBanner && <div style={{
              position: 'fixed',
              bottom: '0',
              width: '100%',
              height: '50px',
              background: 'green',
              color: 'white'
            }}>
                  <button onClick={updateNow}>Nova versão, toque para atualizar agora.</button>
                </div>}
              <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
            </PlausibleProvider>
          </QueryClientProvider>
        </SupabaseProvider>
      </ChakraProvider>
    </>;
}
export default App;